// ------------------------------------------------------------------------------------------------
// 3rd Party Modules
// ------------------------------------------------------------------------------------------------
import React from 'react';
// ------------------------------------------------------------------------------------------------
// Custom Modules
// ------------------------------------------------------------------------------------------------
import { seoContext } from 'context/seo-context';
import Layout from 'components/Layout';
import './contact.scss';
// ------------------------------------------------------------------------------------------------

const Contact: React.FC = () => {
  return (
    <seoContext.Provider value={{ title: 'Contact' }}>
      <Layout>
        <article>
          <div className="inner">
            <div className="section">
              <div className="section-head">
                <div className="container">
                  <h1 className="section-title">Contact Me</h1>
                  <div className="section-about">Let me know if you have any questions, comments or concerns!</div>
                </div>
              </div>
              <div className="section-body content">
                <div className="container">
                  <form className="contacts" action="https://formspree.io/mnqgolor" method="POST">
                    <div className="form-group">
                      <label htmlFor="email">Your Email Address</label>
                      <input
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="something@website.com"
                        required
                        type="email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="Subject">Subject</label>
                      <input
                        className="form-control"
                        id="Subject"
                        name="subject"
                        placeholder="Question about your post"
                        required
                        type="text"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="Message">Message</label>
                      <textarea
                        className="form-control"
                        id="Message"
                        name="message"
                        placeholder="Your message starts with…"
                        required
                        rows={4}
                      />
                    </div>
                    <button className="btn btn-primary btn-block" type="submit">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </seoContext.Provider>
  );
};

export default Contact;
